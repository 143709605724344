.filter-container {           
      position: absolute;
      top: 70px;
      right: 0;
      transition: right 1s;
      height: auto;
      background-color: white;
}

.filter-container-mobile {           
      position: absolute;
      top: 70px;
      /* left: 0; */
      right: 0;
      transition: right 1s;
      height: auto;
      width: 100%;
      background-color: white;
}