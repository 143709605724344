.paper-container {           
      position: absolute;
      top: 70px;
      /* right: 0; */
      transition: right 1s;
      height: auto;
      background-color: white;
}

.paper-container-mobile {           
      position: absolute;
      top: 70px;
      right: 0;
      left: 0;
      transition: right 1s;
      height: auto;
      background-color: white;
      justify-content: center;
}