.stats-container {           
      position: fixed;
      right: 50px;
      bottom: 50px;
      height: auto;
      width: 500px;
      background-color: white;
      /* padding: 20px; */
}

.stats-container-mobile {           
      position: absolute;
      right: 0;
      bottom: 0;
      height: auto;
      width: 100%;
      background-color: white;
}

.stats-minimizer {
      color: white;
      position: absolute;
      right: 10px;
      bottom: 10px;
      cursor: pointer;
}