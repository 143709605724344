.client-mapContainer {
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  transition: width 0.5s;
}

.mapContainer {
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  transition: bottom 0.5s;
}
