.btn-sync {
      background-color: #FC6100 !important;
}

.MuiButton-root .Mui-disabled {
      opacity: 0.6 !important;
      color: white !important;
}

.spinner {
      margin-left: 10px;
      color: white !important;
}